import React from 'react';
import logoIcon from '../imgs/R.png'; // Replace with your logo icon

import '../style/footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo-section">
        <img src={logoIcon} alt="Logo" className="flogo" />
        <h3>मा. श्री. बळीराम डोळे</h3>
      </div>
      <div className="description">
        <p>जिल्हाध्यक्ष - पुणे शहर व जिल्हा इंटक</p>
        <p>माजी अध्यक्ष - हडपसर ब्लॉक काँग्रेस कमिटी.</p>
        <p>विद्यार्थी - पदवीधर - कामगारांच्या न्याय हक्कासाठी अविरत संघर्ष.</p>
      </div>
    </footer>
  );
};

export default Footer;
