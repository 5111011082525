import React from 'react';
import 'remixicon/fonts/remixicon.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import Profile from './components/Profile';
import Card from './components/Card';
import Work from './components/Work';
import Fd from './components/Fd';
import Footer from './components/Footer';
import './App.css'; // Make sure the styles from your CSS file are imported
import flag from './imgs/flag2.jpg'
import clogo from './imgs/R.png'


const App = () => {
  return (
    <Router>
      <div className="App">
        {/* Navbar component */}
        <Navbar />
        <div >
          {/* First image */}
          <img src={flag} alt="First Image" className="full-screen-image" />
          {/* Second image */}
          <img src={clogo} alt="Second Image" className="watermark" />
        </div>
          <Profile className="grid-item" />
          <Card className="grid-item" />
          <Work className="grid-item" />
          <Fd className="grid-item" />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
