import React from 'react';
import '../style/fd.css';

const Fd = () => {
  

  const projects = [
    {
      title: 'मा.मुख्यमंत्री व्यक्तीमत्त्व',
      description:
        'संयुक्त पूर्व परीक्षेबाबत एकच कटऑफ लावावा अशी उमेदवारांची मागणी आहे. पहिल्यांदाच एवढ्या मोठ्या प्रमाणात जागा निघालेल्या आहेत. आयोगाने विद्यार्थ्यांच्या मागणीचा विचार करून 1:12 रेशिओ घेऊन मुख्यसाठी कट ऑफ लावावा.',
    },
    {
      title: 'राज्यसेवा जाहिरात',
      description: 'राज्यसेवा जाहिरात 2022 जास्त पदांचे मागणीपत्रक साठी आंदोलन इतिहासातील सर्वात जास्त पदांसाठीची जाहिरात प्रसिद्ध.',
    },
    {
      title: 'कर सहाय्यक व लिपिक पदांसाठी',
      description: 'कर सहाय्यक व लिपिक पदांसाठी MPSC ने ठेवलेली typing skill test संदर्भात एकूण 3 आंदोलने केली. उमेदवारांना परीक्षेदरम्यान आलेल्या तांत्रिक अडचणी व अचानक घेतलेली परीक्षा रद्द करून नव्याने परीक्षा mpsc ने घेतली. तांत्रिक अडचणी दूर करून उमेदवारांना दिलासा. असंख्य पदे रिक्त राहणार होते त्यावर आयोगाने काम केले व भरती प्रक्रिया पूर्ण केली.',
    },
    {
      title: 'अनियोजीत राज्यसेवा परीक्षा पॅटर्न यासाठी आंदोलन',
      description: 'अचानक उमेदवारांवर लादलेली राज्यसेवा वर्णनात्मक पॅटर्न परीक्षा 2023 ऐवजी 2025 पासून लागू करावी यासाठी एकूण तीन आंदोलने केली. महाराष्ट्रभर हे आंदोलने गाजली. 2023 ऐवजी 2025 पासून नवीन पॅटर्न लागू झाला.',
    },
    {
      title: 'शिक्षक भरतीसाठी वेगवेगळे आंदोलने',
      description: 'शिक्षक भरतीसाठी वेगवेगळे आंदोलने उमेदवार करत आहेत,त्यांच्यासोबत जाऊन त्यांच्या सर्व समस्या जाणून घेऊन मा.नाना भाऊ पटोले व मा.अतुलदादा लोंढे यांना सदर माहिती सादर करून पुढील कार्यवाही करण्याची विनंती केली. यावर काम सुरु आहे.',
    },
  ];


  return (
    
    <div id='fd'>
      <div className="fd">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <div className='project-title'>
              <h3>{project.title}</h3>
              {/* Display the description here */}
              <div className="project-description">
                <h6 className='fd__subtitle'>{project.description}</h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
  );
};

export default Fd;
