import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/navbar.css';
import rpic from '../imgs/R.png';


const nav__links = [
  {
    icon: 'ri-user-line',
    path: '#profile',
    display: 'प्रोफाइल',
  },
  {
    icon: 'ri-article-line',
    path: '#card',
    display: 'कामगिरी',
  },
  {
    icon: 'ri-database-line',
    path: '#work',
    display: 'प्रगतीपथ',
  },
  {
    icon: 'ri-list-check-3',
    path: '#fd',
    display: 'योजना',
  },
]


const Navbar = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const headerFunc = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      menuRef.current.classList.add('header__shrink');
    } else {
      headerRef.current.classList.remove('header__shrink');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', headerFunc);
    return () => window.removeEventListener('scroll', headerFunc);
  }, []);

  const scrollToSection = (e, path) => {
    e.preventDefault();
    const target = document.querySelector(path);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 80,
        behavior: 'smooth',
      });
    }
  };

  const toggleMenu = () => menuRef.current.classList.toggle('menu__active');

  return (
    <header className="header" ref={headerRef}>
        <div className="nav__wrapper">
          <div className="logo">
            <img src={rpic} alt="" />
          </div>
      <div className="navigation" ref={menuRef} onClick={toggleMenu}>
        <ul className="menu">
          {nav__links.map((item, index) => (
            <li className="menu__item" key={index}>
              <span className='menu__icon-items'><i className={item.icon}></i></span>
              {item.path.startsWith('/') ? (
                <Link to={item.path} className="menu__link">
                  {item.display}
                </Link>
              ) : (
                <a href={item.path} onClick={(e) => scrollToSection(e, item.path)} className="menu__link">
                  {item.display}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
      <span className="mobile__menu" onClick={toggleMenu}>
        <i className="ri-menu-line"></i>
      </span>
    </div>
    </header>
  );
};

export default Navbar;
