import React from 'react';
import profileImage from '../imgs/main_img.jpg'; // Import your profile image
import '../style/profile.css'



const Profile = ({ language }) => {
  const name = 'मा. श्री. बळीराम डोळे';
  const designation = 'जिल्हाध्यक्ष - पुणे शहर व जिल्हा इंटक.';

  const redirectToTwitter = () => {
    window.location.href = 'https://twitter.com/BaliramDoley?t=dxOere1PRjdmVVfhxC4YVA&s=08';
  };
  // const redirectToLinkedin = () => {
  //   window.location.href = 'https://twitter.com/BaliramDoley?t=dxOere1PRjdmVVfhxC4YVA&s=08';
  // };
  const redirectToFacebook = () => {
    window.location.href = 'https://m.facebook.com/baliram.dole.1?refid=52&__tn__=C-R';
  };
  const redirectToInsta = () => {
    window.location.href = 'https://www.instagram.com/baliramdoley/?igshid=MmVlMjlkMTBhMg%3D%3D';
  };

  return (
    
    <div className='profile__items' id='profile'>
      <div className="profile">
        
        <div className="profile-info">
          <h2>{name}</h2>
          <h4 className='profile_designation'>{designation}</h4>
        </div>
        
        <div className="profile-image">
          <img src={profileImage} alt="Profile" className="profile-picture" />
        </div>
        
        <div className='social_media'>
          
          <div className='social__media-links'>
            
              <button onClick={redirectToInsta}><i class="ri-instagram-line"></i></button>
              <button onClick={redirectToTwitter}><i class="ri-twitter-line"></i></button>
              <button onClick={redirectToFacebook}><i class="ri-facebook-box-line"></i></button>
              {/* <button onClick={redirectToLinkedin}><i class="ri-linkedin-box-line"></i></button> */}
          </div>
          <div className='link__title'>
            <h4>सोशल मीडियावर मला फॉलो करुन प्रतिसाद द्या...</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
